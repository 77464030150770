import * as React from "react"

import LayoutComponent from "../components/layout";
import {graphql} from "gatsby";
import MetadataComponent from "../components/fields/metadata";
import RealisationsHeaderComponent from "../components/fields/realisations/header";
import { client } from '../../tina/__generated__/client';
import {useEffect, useState} from "react";
import CallToActionComponent from "../components/fields/callToAction";
import RealisationsComponent from "../components/realisations";

const NosRealisationsPage = ({data}) =>  {
    const [realisations, setRealisations] = useState([]);
    const {frontmatter} = data.markdownRemark;

    useEffect(() => {
        async function retrieveRealisations() {
            let reals = await client.queries.clientsConnection();

            if (typeof reals.data.clientsConnection.edges !== 'undefined') {
                setRealisations(reals.data.clientsConnection.edges)
            }
        }

        if(realisations.length == 0) {
            retrieveRealisations();
        }

        console.log(realisations);
    })

    return (
        <>
            <MetadataComponent
                description={frontmatter.metadata.description}
                title={frontmatter.metadata.title}
                keywords={frontmatter.metadata.keywords}
                code={frontmatter.metadata.code}
            />

            <LayoutComponent activeCategory={"nos-realisations"}>
                <RealisationsHeaderComponent
                    title={frontmatter.header.title}
                    subtitle={frontmatter.header.subtitle}
                    description={frontmatter.header.description}
                    image={frontmatter.header.image}
                    alert={frontmatter.header.alert}
                />

                {/*<RealisationsComponent
                    title={frontmatter.realisations.title}
                    subtitle={frontmatter.realisations.subtitle}
                    description={frontmatter.realisations.description}
                    realisations={realisations}
                />*/}
                
                <hr/>

                <RealisationsComponent nbRealisations={12} displayTitle={false}/>

                <CallToActionComponent
                    title={frontmatter.callToAction.title}
                    link={frontmatter.callToAction.link}
                    reference={frontmatter.callToAction.reference}
                    image={frontmatter.callToAction.image}
                />
            </LayoutComponent>
        </>
    );
}

export default NosRealisationsPage;

export const pageQuery = graphql`
query {
  markdownRemark(frontmatter: {metadata: {code: {eq: "realisations"}}}) {
      frontmatter {
        ...MetadataFields
        ...HeaderFields
        ...RealisationsFields
        ...CallToActionFields
      }
  }
}
`
