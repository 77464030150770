import {graphql} from "gatsby";
import React from "react";

const RealisationsHeaderComponent = ({image, title, subtitle, description}) => {
    console.log(image);
    return (
        <header>
            <div className="page-header min-vh-50" style={{backgroundImage: `url(${image})` }}>
                <span className="mask bg-gradient-dark"></span>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto text-white text-center">
                            <h2 className="text-white">{title}</h2>
                            <p className="lead">{subtitle}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {/*<div className="row bg-white shadow-lg mt-n6 border-radius-md pb-4 p-3 mx-sm-0 mx-1 position-relative">
                    <div className="col-lg-3 mt-lg-n2 mt-2">
                        <label className="">Marque</label>
                        <select className="form-control" name="choices-leave" id="choices-leave" placeholder="Marque">
                            <option value="Choice 1" selected="">Renault</option>
                            <option value="Choice 2">Peugeot</option>
                            <option value="Choice 3">Citroën</option>
                            <option value="Choice 4">Volkswagen</option>
                        </select>
                    </div>
                    <div className="col-lg-3 mt-lg-n2 mt-2">
                        <label className="">Modèle</label>
                        <select className="form-control" name="choices-to" id="choices-to" placeholder="Modèle">
                            <option value="Choice 1" selected="">Clio</option>
                            <option value="Choice 2">Mégane</option>
                            <option value="Choice 3">Laguna</option>
                            <option value="Choice 4">Captur</option>
                        </select>
                    </div>
                    <div className="col-lg-3 mt-lg-n2 mt-2">
                        <label className="">Année-modèle</label>
                        <select className="form-control" name="choices-year" id="choices-year" placeholder="Année-modèle">
                            <option value="Choice 1" selected="">2009</option>
                            <option value="Choice 2">2010</option>
                            <option value="Choice 3">2011</option>
                            <option value="Choice 4">2012</option>
                        </select>
                    </div>
                    <div className="col-lg-3 d-flex align-items-center my-auto">
                        <button type="button" className="btn bg-gradient-dark w-100 btn-lg mb-0 m4 mt-3">Rechercher</button>
                    </div>
                </div>*/}
            </div>
        </header>
    )
}

export default RealisationsHeaderComponent;

export const query = graphql`
  fragment HeaderFields on MarkdownRemarkFrontmatter {
    header {
      title
      subtitle
      description
      image
      alert
    }
  }
`;
